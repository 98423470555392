import React from 'react'
import './profile.scss'
import Sidebar from '../../components/sidebar/Sidebar'



const Profile = () => {
  return (
    <div className='profile'>
      <Sidebar />
      <div className='profileContainer'>

      </div>
    </div>
  )
}

export default Profile